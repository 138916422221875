import styled, { css } from 'styled-components/macro'
import { _project } from 'state/store.projects'
import { vis_overlay } from 'state/store.global'
import { active_unit } from 'state/store.global'
import { useGet, useAtom } from 'state/jotai'
import { useUnit, usePick } from './Isometry.utilities'
import { motion, AnimatePresence } from 'framer-motion'
import { open_drawer } from 'state/actions'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const Svg = ({ svg, percent, units }) => {

  const vbox = svg?.attributes?.viewBox
  const blur = Math.abs(percent-100)/3
  const opac = percent/100
  const draw = useAtom(open_drawer)[1]
  const geta = useAtom(active_unit)[0]
  const seta = useAtom(active_unit)[1]
  const show = useGet(vis_overlay)
  const pick = usePick()
  const unit = useUnit()
  
  const { i18n:{language} } = useTranslation()

  const navigate = useNavigate()
  
  return (
    <AnimatePresence>
    {
      units && show && (
        <svg viewBox={vbox} style={{position:'absolute'}}>
          { 
            svg?.children?.map( ({attributes:{id,d}}) => {
              const ID      = id?.replace(/nr/g,'')
              const address = isNaN(ID) ? '' : unit(ID,'Adresse')
              const sID     = isNaN(ID) ? '' : unit(ID,'UnitStatusID')
              const sName   = isNaN(ID) ? '' : unit(ID,'UnitStatusName')
              const color   = isNaN(ID) ? '#ffffff00' : unit(ID,'UnitColor','f')
              const picked  = isNaN(ID) ? '' : pick(ID)
              const UnitID  = isNaN(ID) ? '' : unit(ID,'UnitID')
              const Unit    = isNaN(ID) ? '' : unit(ID,0,'i')
              const isActive = geta.UnitID === UnitID
              const onClick = () => {              
                if ( UnitID === false ) return 
                if ( isNaN(ID) ) navigate(`/${language}/isometry/${ID}`)
                if ( sID == 40 ) return
                if ( sID !== 40 ) {
                  seta( Unit )
                  draw('detail')
                  log({action:'clicked_apartment_on_isometry',unitId:UnitID})
                }
              }
              const style = {filter:`blur(${blur}px)`,opacity:isNaN(ID) ? '1' : opac}
              const key = d
              const path = { d, id, key, style, onClick, sID, sName, color, picked, active:isActive, ID }
              if ( !d.includes('M0') ) return <Path {...path}/>
          })}
        </svg>
      )
    }
    </AnimatePresence>
  )
}

const Path = styled.path( ({sID, color, picked, active, ID}) => css`
  cursor: ${ sID == 40 ? 'not-allowed' : 'pointer' };
  fill: #fff;
  stroke: #000;
  stroke-miterlimit: 10;
  ${ () => {
    if ( active ) return 'fill: #FFFFFF90;'
    if ( picked ) return 'fill: #FF00FF;'
    if ( color.value == 'err' ) {
      if ( sID == 10 ) return 'fill: #00ff0080;'
      if ( sID == 20 ) return 'fill: #ffff0080;'
      if ( sID == 30 ) return 'fill: #0000ff80;'
      if ( sID == 40 ) return 'fill: #ff000080;'
    }
    if ( isNaN(ID) ) {
      return `fill: ${color};`
    }
    return ( typeof color === 'string' ) 
      ? `fill: ${color}80;` 
      : 'fill: #3A3A3A80;'
  }};
  ${ typeof color !== 'string' } {
    :hover { fill: #ffffff80; }
  }
`)
