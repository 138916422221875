import { useState }                 from 'react'
import { motion }                   from 'framer-motion'
import { AnimatePresence }          from 'framer-motion'
import { Menu, Tile, Split, Text }  from './Nav.Desktop.Foldout.style'
import { Icon }                     from 'assets/Icons_'
import { Link }                     from 'react-router-dom'
import { MENU }                     from 'constants'
import { useDetectClickOutside }    from 'react-detect-click-outside'
import { useTranslation }           from 'react-i18next'
import { _project }                 from "state/store.projects";
import { active_project, vis_desktop_nav }           from "state/store.global"; 
import { getFocus, useGet }         from "state/jotai";
import { Bottom } from 'components/List/List.style'
import { PoweredBy } from 'components/PoweredBy/PoweredBy'

export const Foldout = ({nav,show,setShow}) => {
  const { pin, pid }  = useGet(active_project);
  const menus = getFocus(_project, "menuItems")?.[pin]?.[pid] ?? [];
 
  const { i18n:{language} } = useTranslation()
  // const outside             = { onTriggered: () => setShow(!show) }
//  const IO = {
//  click    : () => setShow(show ? false : true)
//  }
  // const ref                 = useDetectClickOutside(outside)
  const menu                = {
    initial   : { x:-MENU.DESKTOP_NAV_DRAWER_W+'px' },
    animate   : { x:100+'px' },
    exit      : { x:-MENU.DESKTOP_NAV_DRAWER_W+'px' },
    transition: { duration:0.3, ease:'linear' },
  }

  const menusItems = menus.map( (items, index) => {
  const item = {};
  for (const key in items) {
    if (items.hasOwnProperty(key)) {
      item[key.toLowerCase()] = items[key];
    }
  }
  return item; 
  })

  // removing duplicate data
  let jsonObject = menusItems.map(JSON.stringify);
  let uniqueSet = new Set(jsonObject);
  let uniqueArrayMenusItems = Array.from(uniqueSet).map(JSON.parse);

  return (
    <AnimatePresence>
      { show && <Menu 
          as  = {motion.div}
          clr = {clr} {...menu}
            // ref={ref}
        >
          <div style = {{flex:1}}/>
          { 
            uniqueArrayMenusItems.map(({url,text,icon},j) => {
              const ico = {href:url[language], icon, size:45, color:clr.icons,}

              return ( 
                <div key={text} onClick={()=> setShow(false)}  >
                  { j > 0 && <Split clr={clr}/> }
                <Link clr = {clr} to={url} key={text} style={{textDecoration:'none'}} >
                    <Tile clr={clr}>
                      <Icon {...ico} />
                      <Text clr={clr}>{ text }</Text>
                    </Tile>
                  </Link>
            
                </div>
              )
            })
          }
          
          <div style = {{flex:1}}/>
          <PoweredBy clr={clr.sub_text_color}/>
        </Menu>
      }
    </AnimatePresence>
  )
}
