import { useEffect }          from 'react'
import { _bootstrap }         from 'state/store.bootstrap'
import { _project }           from 'state/store.projects'
import { active_project, active_unit, current_language }     from 'state/store.global'
import { useFetch }           from 'hooks/fetch/useFetch'
import { useFetch_Units }     from 'hooks/fetch/useFetch_Units'
import { useGet, setFocus, useAtom, get }   from 'state/jotai'
import { PROJECT, PAGE, DOWNLOADS }      from 'api/api'
import { fn3 }                from 'utilities/utility.misc'
import { initialsort as isr } from 'utilities/utility.sort'
import { useTranslation }     from 'react-i18next'
import { dropdownOptions } from 'components/Dropdown2/Dropdown2.Data'
import { useLocation } from 'react-router-dom'

export const ProjectData_Poly = () => {

  const { i18n:{language} } = useTranslation()
  const type                = useGet(_bootstrap).type
  const { pin, pid }        = useGet(active_project)
  
  const [activeUnits, setActiveUnits] = useAtom(active_unit)
  const units                         = get(_project, 'units', 'Units', false)
  
  const poly = type === 'poly'
  const file = poly ? 'main/ProjectData.jsx' : ''

  // const url2 = pid ? PAGE(pid,1050,language) : null
  const url1 = pid ? PROJECT(pid,language)    : null
  const url2 = pid ? DOWNLOADS(pid)  : null
  const url3 = pid ? PAGE(pid,1080,language) : null

  const project   = useFetch({ url:poly?url1:null, file }).D
  const downloads = useFetch({ url:poly?url2:null, file }).D
  const gallery   = useFetch({ url:poly?url3:null, file }).D
  // console.log(project, 'project')
  const setCurrent_language = useAtom(current_language)[1];

  useFetch_Units({ pid:poly?pid:null, pin:poly?pin:null })

  if(Object.keys(activeUnits || {}).length && units.length) {
    let translatedUnits = units?.filter( u => u.UnitID == activeUnits.UnitID )
    if(translatedUnits.length) setActiveUnits(translatedUnits[0])
  }

  const setCardtext          = setFocus(_project, 'cardtext')
  const setStatustypes       = setFocus(_project, 'statustypes')
  const setUnittypes         = setFocus(_project, 'unittypes')
  const setSorteroptions     = setFocus(_project, 'sorteroptions')
  const setFilteroptions     = setFocus(_project, 'filteroptions')
  const setHandpicked        = setFocus(_project, 'handpicked')
  const setDefaultsort       = setFocus(_project, 'activesorting')
  const setOpenhouse         = setFocus(_project, 'openhouse')
  const setGallery           = setFocus(_project, 'gallery')
  const setDownloads         = setFocus(_project, 'downloads')
  const setButtonsLabel      = setFocus(_project, 'buttonsLabel')
  const setUnitStatus        = setFocus(_project, 'unitStatuses')
  const setHighlights        = setFocus(_project, 'Highlights')
  const setContactProperties = setFocus(_project, 'ContactProperties')
  const setIconLookup        = setFocus(_project, 'IconLookup')
  const setMenuItems         = setFocus(_project, 'menuItems')

  useEffect(()=>{
    
    if ( poly && project && downloads && gallery && pin >= 0 ) {
      
      const p = project.config
      
      setCardtext         ( a => fn3(a, pin, pid, p.ShowInListView   ) )
      setStatustypes      ( a => fn3(a, pin, pid, p.UnitStatuses     ) )
      setUnittypes        ( a => fn3(a, pin, pid, p.SaleAndUnitTypes ) )
      setSorteroptions    ( a => fn3(a, pin, pid, p.SortOrders       ) )
      setFilteroptions    ( a => fn3(a, pin, pid, p.Filters          ) )
      setHandpicked       ( a => fn3(a, pin, pid, [1,2,3,111,200]    ) )
      setDefaultsort      ( a => fn3(a, pin, pid, isr(p.SortOrders)  ) )
      setOpenhouse        ( a  => fn3(a, pin, pid, project.OpenHouse ) )
      setGallery          ( a => fn3(a, pin, pid, gallery.Components[1] ))
      setDownloads        ( a => fn3(a, pin, pid, downloads ) )
      setButtonsLabel     ( a => fn3(a, pin, pid, project.LabelProperties  ))
      setUnitStatus       ( a => fn3(a, pin, pid, project.config.UnitStatuses ))
      setHighlights       ( a => fn3(a, pin, pid, project.Highlights ))
      setContactProperties( a => fn3(a, pin, pid, project.ContactProperties ))
      setIconLookup       ( a => fn3(a, pin, pid, project.IconLookupProperties ))
      setMenuItems        ( a => fn3(a, pin, pid, project.MenuProperties ))
      setCurrent_language (project.Language)
    }
  },[poly, project, downloads, gallery, pid, pin])
  
}